<template>
    <div class="step0">
        <div class="stepLeft">
            <div class="title">{{$t('guide.welcome')}}</div>
            <div class="sub">{{$t('guide.guideDetail')}}</div>
            <el-steps :active="-1" direction="vertical">
                <el-step :title="$t('guide.selectWeb')"></el-step>
                <el-step :title="$t('guide.bindAccount')"></el-step>
                <el-step :title="$t('guide.selectRobot')"></el-step>
            </el-steps>
            <div class="g_btn btn" @click="nextStep">{{$t('common.start')}}</div>
            <div class="g_btn skipBtn" @click="skipTutorial">{{$t('guide.skipTutorial')}}</div>
        </div>
        <div class="stepRight">
            <img src="@/assets/images/guide/banner1.png" alt="">
        </div>
    </div>
</template>
<script>
    export default {
        methods: {
            nextStep() {
                this.$emit('nextStep');
            },
            skipTutorial() {
                this.$emit('skipTutorial');
            },
        },
    };
</script>
<style lang="stylus" scoped>
    @import './step0.styl';
</style>