import store from '@/store'

const { body } = document
const WIDTH = 993 // refer to Bootstrap's responsive design
const WIDTH2 = 1430 // refer to Bootstrap's responsive design

export default {
  watch: {
    $route(route) {
      if (this.device === 'mobile' && this.isCollapse) {
        store.commit('app/SET_IS_COLLAPSE', true);
        this.notTrans = false;
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')
    if (isMobile) {
      store.commit('app/SET_IS_COLLAPSE', true);
      this.notTrans = true;
    }

    const isMobile2 = this.$_isMobile2()
    store.dispatch('app/toggleDevice2', isMobile2 ? 'mobile' : 'desktop')

    const isSmall = this.$_isSmall()
    if (isSmall) {
      store.dispatch('app/vwDevice', 'small')
    }
  },
  methods: {
    // use $_ for mixins properties
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_isMobile2() {
      const width = window.innerWidth || body.getBoundingClientRect().width
      return width - 1 < WIDTH2
    },
    $_isSmall() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < 750
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')

        const isMobile2 = this.$_isMobile2()
        store.dispatch('app/toggleDevice2', isMobile2 ? 'mobile' : 'desktop')

        const isSmall = this.$_isSmall()
        store.dispatch('app/vwDevice', isSmall ? 'small' : 'normal')

        this.notTrans = true;
        if (isMobile) {
          store.commit('app/SET_IS_COLLAPSE', true);
        }
      }
    }
  }
}
