<template>
  <div class="step">
    <div class="title">{{ $t("guide.selectWeb") }}</div>
    <div class="sub">{{ $t("guide.selectWebSub") }}</div>
    <el-form
      :model="exchangeData"
      :rules="rules"
      ref="exchangeForm"
      class="formBox"
    >
      <el-form-item prop="web">
        <div class="select" @click="openExchangePop">
          <input
            v-model="exchangeData.exchange"
            type="text"
            :placeholder="$t('common.select')"
            readonly
            class="inp"
          />
          <i class="iconfont icon-down"></i>
        </div>
      </el-form-item>
      <template v-if="stepNum == 1">
        <el-form-item prop="yenUnit1">
          <el-input
            :placeholder="$t('guide.baseCurrencyPH')"
            v-model="exchangeData.yenUnit1"
            autocomplete="off"
            class="inp"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="yenUnit2">
          <el-input
            :placeholder="$t('guide.quoteCurrencyPH')"
            v-model="exchangeData.yenUnit2"
            autocomplete="off"
            class="inp"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
    <div class="g_btn btn" @click="nextStep">{{ $t("common.next") }}</div>

    <div class="tip">
      <div class="tipTitle">
        <img src="@/assets/images/guide/tip.png" alt="" />
        {{ $t("guide.req1") }}
      </div>
      <div class="detail">{{ $t("guide.ans1") }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["stepNum"],
  data() {
    return {
      exchangeData: {
        exchange: "",
        wallet_type: "",

        web: "",
        yenUnit1: "",
        yenUnit2: "",
      },
      rules: {
        web: {
          required: true,
          message: this.$t("tip.selectExchange"),
          trigger: "change",
        },
        yenUnit1: {
          required: true,
          message: this.$t("guide.baseCurrencyPH"),
          trigger: "blur",
        },
        yenUnit2: {
          required: true,
          message: this.$t("guide.quoteCurrencyPH"),
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    nextStep() {
      this.$refs.exchangeForm.validate((valid) => {
        if (valid) {
          if (this.stepNum == 1) {
            this.$emit("nextStep", this.exchangeData);
          } else {//stepNum==0
            this.$emit("update:stepNum", 1);
          }
        } else {
          return false;
        }
      });
    },

    // 交易所
    openExchangePop() {
      this.$emit("openExchangePop", this.exchangeData.web);
    },
    handleSelect(data) {
      this.$set(this.exchangeData, "web", data.web);
      this.$set(this.exchangeData, "exchange", data.text);
      this.$set(this.exchangeData, "web_type", data.web_type);
      // 清除验证
      this.$refs.exchangeForm.clearValidate("web");
    },
  },
};
</script>
<style lang="stylus" scoped>
@import './step.styl';
@import './step1.styl';
</style>