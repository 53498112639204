// 新手引导相关接口
import {http} from '@/utils/http'

// 选择交易所及填写交易对
// 'web', 'account', 'coin_token', 'money_token'
// coin_token 用户自己发行的币名称
// money_token 稳定币名称：例如USDT BNB
export const bindWebPair = data => http('/Beginner_guidance/bind_web_and_pair',data,'post')

// 添加token
// 'web', 'account', 'address', 'decimal_num','fee'
// address 代币地址
// decimal_num 精度
// fee 费用
export const addToken = data => http('/Beginner_guidance/add_token',data,'post')

// 绑定钱包或apikey
// 'web', 'account', 'key', 'secret'
// key apikey或钱包地址
// secret 密钥或钱包私钥
export const bindKey = data => http('/Beginner_guidance/bind_key',data,'post')

// 获取已绑定dex账户中指定交易对的余额，用于分拨时展示
// 'web', 'account'
export const getDexBalance = data => http('/Beginner_guidance/get_dex_balance',data,'post')

// 分拨
// 'web', 'account','num','approve','allocation_data'
// num 新建子钱包数量
// approve 授权内容 {"usdt":"9999.99","usdc":"999.999"}
// allocation_data 分拨内容 {"usdt":"99.99","usdc":"99.99"}
export const allocation = data => http('/Beginner_guidance/allocation',data,'post')

// 机器人配置保存
// 'web', 'account','opening_time','robot_param'
// opening_time 开盘时间
// robot_param 机器人参数
export const robotParam = data => http('/Beginner_guidance/robot_param',data,'post')